<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- <el-button class="filter-item" type="primary" @click="handleEdit('')"
        >新增</el-button
      > -->
      <el-input
        v-model="listQuery.title"
        placeholder="请输入题目名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.type"
        placeholder="请选择题目类型"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="单选题" :value="1" />
        <el-option label="多选题" :value="2" />
        <el-option label="填空题" :value="3" />
        <el-option label="简答题" :value="4" />
        <el-option label="判断题" :value="5" />
      </el-select>
      <!-- <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select> -->
      <el-button class="filter-item" type="primary" @click="getList"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="题库分类" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.question_bank_type.name }}
        </template>
      </el-table-column>
      <el-table-column label="题目类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.type == 1
              ? "单选题"
              : scope.row.type == 2
              ? "多选题"
              : scope.row.type == 3
              ? "填空题"
              : scope.row.type == 4
              ? "简答题"
              : "判断题"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目内容" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sort_weight }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_on">正常</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleSchedule(scope.row, 1)"
          >
            答题记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: [],
      listQuery: {
        page: 1,
        limit: 10,
        type: null,
        name: null,
        is_on: null,
      },
      total: null,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      this.listLoading = true;
      // this.listQuery.category_id = this.form.category_id;
      request({
        url: "/api/teacherend/studentQuestionBank/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(val) {
      this.$router.push(`/question/topic_edit?id=${val}&type=1`);
    },
    handleSchedule(val) {
      this.$router.push(`/question/answer_record?id=${val.id}&type=1`);
    },
    handleSort(val) {
      this.$prompt("请输入排序", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\d+$|^\d+[.]?\d+$/,
        inputErrorMessage: "排序格式不正确",
      })
        .then(({ value }) => {
          request({
            url: "/api/teacherend/studentQuestionBank/sortStore",
            method: "post",
            data: { id: val, sort_weight: value },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleDel(val) {
      this.$confirm("此操作将删除该题目, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/studentQuestionBank/del",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该题目, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/studentQuestionBank/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.el-icon-edit {
  margin-left: 5px;
}
</style>